/*--------------------------------------------------------
/
/    [RE]noun Creative WSK Page Section Builder
/     Version: 2.0.1
/     File: Custom functions for Page Sections
/
/-------------------------------------------------------*/

jQuery(document).ready(function($){


/*---------------------------------------------
/
/  	Function for WooCommerce Product Slider
/
/_____________________________________________*/

	$product_sliders = $('.product-slider .product-wrapper');

	if ($product_sliders.length > 0) {
		$product_sliders.each(function() {
			$(this).find('.products').unwrap().find('li:first-child').unwrap();
			
			$(this).slick({
				dots: false,
				infinite: true,
				slidesToShow: 3,
				fade: false,
				adaptiveHeight: true,
				swipeToSlide: true,
				slide: '.product',
				responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: 3
			      }
			    },
			    {
			      breakpoint: 739,
			      settings: {
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {
			        slidesToShow: 1,
			      }
			    }]
			});
		})
	}

/*---------------------------------------------
/
/  	    Function for Slider Media Items
/
/_____________________________________________*/

	$sliders = $('.media-item .slider-container');

	//if there are sliders on this page, set each up based on specified theme
	if ($sliders) {
	  var sliders = {
	    init: function() {
	      this.cacheDom();
	      this.setupSliders();
	    },
	    cacheDom: function() {
	     
	    },
	    setupSliders: function() {
	      $sliders.each(function() {
	        sliderName = $(this).attr('data-slider'),
	        console.log("Setting up the " + sliderName + " slider.");
	        sliderType = $(this).attr('data-type'),
	        $(this).slick(sliders.getTypeOptions(sliderType));
	      });
	    },
	    getTypeOptions: function(type) {
	      if (type == 'testimonial') {
	        var options = {
	              dots: true,
	              infinite: true,
	              slidesToShow: 1,
	              fade: false,
	              adaptiveHeight: true,
	              swipeToSlide: true,
	              slickFilter: '.slide',
	        }
	        return options;

	      } else if (type == 'content-block') {
	        var options = {
	              dots: false,
	              infinite: true,
	              slidesToShow: 3,
	              fade: false,
	              adaptiveHeight: true,
	              swipeToSlide: true,
	              slickFilter: '.slide',
	              responsive: [
	              {
	                breakpoint: 701,
	                settings: "unslick",
	              },
	              {
	                breakpoint: 700,
	                settings: {
	                  slidesToShow: 1,
	                  autoplay: false,
	                  dots: true,
	                  autoplay: false,
	                }
	              }]
	        }
	        return options;

	      } else {

	        return false;
	      }
	    },
	  }; //end sliders object

	  sliders.init();
	};

}); /*end on ready*/